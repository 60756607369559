import axios from "axios";
import { ElMessage } from "element-plus"
import { ProjectUserRepository } from '@/repositories/ProjectUserRepository'
import { ProjectUser } from '@/types/project_user'
import { ref, Ref, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'

type Response = {
  users: Ref<ProjectUser[]>;
  loading: Ref<boolean>;
  handleAdd: Function;
  handleDelete: Function;
  changeOwnerFlg: Function;
}

export const useProjectUserList: Function = (): Response => {
  const users: Ref<ProjectUser[]> = ref<ProjectUser[]>([]);
  const loading: Ref<boolean> = ref(false);
  const successMessage: Ref<string> = ref('');
  const errorMessage: Ref<string> = ref('');
  const route = useRoute();

  const load = async (projectId: number): Promise<void> => {
    const repository = new ProjectUserRepository(projectId);

    loading.value = true;
    try {
      users.value = await repository.getList();
      loading.value = false;
    } catch (e: unknown) {
      if (axios.isAxiosError(e) && e.response) {
        errorMessage.value = `プロジェクトメンバーの読み込みに失敗しました。再読み込みをしてお試し下さい(status: ${e.response.status})`;
      }
    }
  };

  onMounted(() => {
    load(Number(route.params.project_id));
  });
  watch(() => route.params.project_id, (projectId): void => {
    if (projectId) {
      load(Number(projectId));
    }
  });

  const handleAdd = async (userIds: number[]) => {
    if (userIds.length === 0) {
      return;
    }

    const projectId = Number(route.params.project_id);

    try {
      const repository = new ProjectUserRepository(projectId);
      await repository.add(userIds);
      successMessage.value = 'プロジェクトメンバーを追加しました。';
    } catch (e: unknown) {
      if (axios.isAxiosError(e) && e.response) {
        errorMessage.value = `プロジェクトメンバーの追加に失敗しました。再読み込みをしてお試し下さい(status: ${e.response.status})`;
      }
    }

    load(projectId);
  };

  const handleDelete = async (user: ProjectUser) => {
    const projectId = Number(route.params.project_id);

    try {
      const repository = new ProjectUserRepository(projectId);
      await repository.delete(user);
      successMessage.value = 'プロジェクトメンバーを削除しました。';
    } catch (e: unknown) {
      if (axios.isAxiosError(e) && e.response) {
        errorMessage.value = `プロジェクトメンバーの削除に失敗しました。再読み込みをしてお試し下さい(status: ${e.response.status})`;
      }
    }

    load(projectId);
  };

  const changeOwnerFlg = async (user_id: number, owner_flg: boolean) => {
    const projectId = Number(route.params.project_id);
    try {
      const repository = new ProjectUserRepository(projectId);
      await repository.changeOwnerFlg(user_id, owner_flg);
      successMessage.value = '権限変更しました';
    } catch (e: unknown) {
      if (axios.isAxiosError(e) && e.response) {
        errorMessage.value = `権限変更に失敗しました。再読み込みをしてお試し下さい(status: ${e.response.status})`;
      }
    }

  }

  watch(successMessage, () => {
    if (successMessage.value === "") {
      return;
    }
    ElMessage({
      showClose: true,
      message: successMessage.value,
      type: 'success'
    });
    successMessage.value = "";
  });
  watch(errorMessage, () => {
    if (errorMessage.value === "") {
      return;
    }
    ElMessage({
      showClose: true,
      message: errorMessage.value,
      type: 'error'
    });
    errorMessage.value = "";
  });

  return {
    users,
    loading,
    handleAdd,
    handleDelete,
    changeOwnerFlg
  };
};
